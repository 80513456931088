import { useCallback, useState } from "react";
import { PasswordRequirementProps } from "@sgi/gravity/dist/components/forms/PasswordInput/PasswordInput";

type PasswordRequirement = PasswordRequirementProps & { validate: (value: string) => boolean }

const defaultPasswordRequirements: PasswordRequirement[] = [
  {
    requirement: "Be at least 8 characters in length",
    status: "default",
    validate: (value: string) => value.length >= 8,
  },
  {
    requirement: "Include at least one upper case letter (A-Z)",
    status: "default",
    validate: (value: string) => /[A-Z]/.test(value),
  },
  {
    requirement: "Include at least one lower case letter (a-z)",
    status: "default",
    validate: (value: string) => /[a-z]/.test(value),
  },
  {
    requirement: "Include at least one number (0-9)",
    status: "default",
    validate: (value: string) => /\d/.test(value),
  },
  {
    requirement: "Include at least one symbol (#@$!)",
    status: "default",
    validate: (value: string) => /[@#$%^&*\-_+=[\]{}|\\:',.?/`~"();!]/.test(value),
  },
];

export function usePasswordRequirements() {
  const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirement[]>([]);
  const validatePasswordRequirements = useCallback((password: string) => {
    let allValid = true;
    const passwordRequirements: PasswordRequirement[] = [];
    for (const passwordRequirement of defaultPasswordRequirements) {
      const isValid = passwordRequirement.validate(password);
      passwordRequirements.push({ ...passwordRequirement, status: isValid ? "success" : "error" });
      allValid &&= isValid;
    }
    setPasswordRequirements(passwordRequirements);
    return allValid;
  }, []);
  const resetPasswordRequirements = useCallback(() => setPasswordRequirements([]), []);
  const showPasswordRequirements = useCallback(() => setPasswordRequirements(defaultPasswordRequirements), []);
  return { passwordRequirements, resetPasswordRequirements, showPasswordRequirements, validatePasswordRequirements };
}

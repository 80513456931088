import { Alert, Link, Modal, PasswordInput, Typography } from "@sgi/gravity";
import React, { ChangeEvent, ReactNode, useCallback, useState } from "react";
import { mustGetElementById, useChangePasswordProxy } from "../Custom Hooks/useChangePasswordProxy";
import { usePasswordRequirements } from "../Custom Hooks/usePasswordRequirements";

const errFieldRequired = "This field is required";
const errPasswordMismatch = "Password mismatch";

function validatePassword(value: string, setError: (err: ReactNode) => void) {
  const isValid = value.length > 0;
  setError(isValid ? null : errFieldRequired);
  return isValid;
}

function validateConfirmNewPassword(value: string, expected: string, setError: (err: ReactNode) => void) {
  if (value.length === 0) {
    setError(errFieldRequired);
    return false;
  }
  if (value !== expected) {
    setError(errPasswordMismatch);
    return false;
  }
  setError(null);
  return true;
}

export type ChangePasswordModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

export default function ChangePasswordModal({ isOpen, onClose }: ChangePasswordModalProps) {
  const {
    cancelChangePassword, clearChangePasswordError, changePasswordError, submitChangePassword
  } = useChangePasswordProxy(mustGetElementById);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState<ReactNode>(null);
  const handlePasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value, setPasswordError);
  }, []);
  const handlePasswordBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    validatePassword(e.target.value, setPasswordError);
  }, []);

  const [newPassword, setNewPassword] = useState("");
  const {
    passwordRequirements, resetPasswordRequirements, validatePasswordRequirements, showPasswordRequirements
  } = usePasswordRequirements();
  const handleNewPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePasswordRequirements(value);
  }, [validatePasswordRequirements]);
  const handleNewPasswordFocus = useCallback(() => showPasswordRequirements(), [showPasswordRequirements]);

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<ReactNode>(null);
  const handleConfirmNewPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(e.target.value);
    setConfirmNewPasswordError(null);
  }, []);
  const handleConfirmNewPasswordBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    validateConfirmNewPassword(e.target.value, newPassword, setConfirmNewPasswordError);
  }, [newPassword]);

  const handleContinueClick = useCallback(
    () => {
      clearChangePasswordError();
      const passwordValid = validatePassword(password, setPasswordError);
      const newPasswordValid = validatePasswordRequirements(newPassword);
      const confirmNewPasswordValid =
        validateConfirmNewPassword(confirmNewPassword, newPassword, setConfirmNewPasswordError);
      if (!passwordValid || !newPasswordValid || !confirmNewPasswordValid) return;
      submitChangePassword(password, newPassword, confirmNewPassword);
    },
    [
      clearChangePasswordError, confirmNewPassword, newPassword, password, submitChangePassword,
      validatePasswordRequirements
    ]
  );

  const handleOnClose = useCallback(() => {
    clearChangePasswordError();
    setPassword("");
    setPasswordError(null);
    setNewPassword("");
    resetPasswordRequirements();
    setConfirmNewPassword("");
    setConfirmNewPasswordError(null);
    onClose();
  }, [clearChangePasswordError, onClose, resetPasswordRequirements]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      title="Change Password"
      onCancelClick={cancelChangePassword}
      onContinueClick={handleContinueClick}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <Typography>A strong password helps prevent unauthorized access to your account.</Typography>
      <PasswordInput
        controlStyleProps={{my: "0.5rem"}}
        name="old-password"
        label="Your current password"
        placeholder="Enter current password"
        isRequired
        error={passwordError}
        value={password}
        onChange={handlePasswordChange}
        onBlur={handlePasswordBlur}
        data-testid="password-test"
      />
      <Link>Forgot your password?</Link>
      <PasswordInput
        controlStyleProps={{my: "1rem"}}
        name="new-password"
        label="New password"
        placeholder="Enter new password"
        isRequired
        value={newPassword}
        onChange={handleNewPasswordChange}
        onBlur={handleNewPasswordChange}
        onFocus={handleNewPasswordFocus}
        passwordRequirements={passwordRequirements}
        data-testid="new-password-test"
      />
      <PasswordInput
        name="reenter-password"
        label="Re-enter new password"
        placeholder="Re-enter new password"
        isRequired
        error={confirmNewPasswordError}
        value={confirmNewPassword}
        onChange={handleConfirmNewPasswordChange}
        onBlur={handleConfirmNewPasswordBlur}
        data-testid="reenter-password-test"
      />
      {changePasswordError && (
        <Alert status="error" alertStyleProps={{mt: "1rem"}} showCloseButton={false}>{changePasswordError}</Alert>
      )}
    </Modal>
  );
}

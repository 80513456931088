import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getAppConfig } from "./appConfig";
import { theme, ThemeProvider } from "@sgi/gravity";

declare global {
  interface Window {
    SETTINGS?: unknown;
    CONTENT: {
      error_fieldIncorrect: string;
      error_passwordEntryMismatch: string;
      error_requiredFieldMissing: string;
    }
  }
}

renderApp();
reportWebVitals();

export default async function renderApp() {
  replaceBaseUrl();

  console.log("Settings: ", window.SETTINGS);
  console.log("Content: ", window.CONTENT);

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
}

function replaceBaseUrl() {
  const config = getAppConfig();

  const local = document.querySelector("base") as HTMLElement;
  document.head.removeChild(local);

  const defaultBaseElement = document.createElement("base");
  defaultBaseElement.href = config.REACT_APP_BASEURL;

  document.head.appendChild(defaultBaseElement);
}

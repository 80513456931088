import { useCallback, useEffect, useState } from "react";

export function mustGetElementById<T extends HTMLElement>(id: string): T {
  const elementOrNull = document.getElementById(id) as T | null;
  if (elementOrNull === null) throw new Error(id + ' is missing in the document');
  return elementOrNull;
}

export function useChangePasswordProxy(getElementById: typeof mustGetElementById) {
  const submitChangePassword = useCallback((oldPassword: string, newPassword: string, reenterPassword: string) => {
    getElementById<HTMLInputElement>('oldPassword').value = oldPassword;
    getElementById<HTMLInputElement>('newPassword').value = newPassword;
    getElementById<HTMLInputElement>('reenterPassword').value = reenterPassword;
    getElementById<HTMLButtonElement>('continue').click();
  }, [getElementById]);

  const cancelChangePassword = useCallback(() => {
    getElementById<HTMLButtonElement>('cancel').click();
  }, [getElementById]);

  const [changePasswordError, setChangePasswordError] = useState("");
  const clearChangePasswordError = useCallback(() => setChangePasswordError(""), []);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        const element = mutation.target as HTMLElement;
        switch (element.id) {
          case 'claimVerificationServerError':
            setChangePasswordError(element.textContent ?? "");
            break;
          case 'fieldIncorrect':
            setChangePasswordError(window.CONTENT.error_fieldIncorrect);
            break;
          case 'passwordEntryMismatch':
            setChangePasswordError(window.CONTENT.error_passwordEntryMismatch);
            break;
          case 'requiredFieldMissing':
            setChangePasswordError(window.CONTENT.error_requiredFieldMissing);
            break;
        }
      }
    });
    // JD: first 3 errors should not happen since we handle them in our form, but to be safe still handle them
    observer.observe(getElementById('passwordEntryMismatch'), { attributes: true, attributeFilter: ["aria-hidden"] });
    observer.observe(getElementById('requiredFieldMissing'), { attributes: true, attributeFilter: ["aria-hidden"] });
    observer.observe(getElementById('fieldIncorrect'), { attributes: true, attributeFilter: ["aria-hidden"] });
    observer.observe(getElementById('claimVerificationServerError'), { attributes: true, attributeFilter: ["aria-hidden"] });

    return () => observer.disconnect();
  }, [getElementById]);

  return { cancelChangePassword, clearChangePasswordError, changePasswordError, submitChangePassword };
}
